import ScrollTop from 'components/ScrollTop';
import ThemeCustomization from 'themes/index';
import MainRoutes from 'routes/MainRoutes';


const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <MainRoutes/>
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
