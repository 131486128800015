import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true,
  isAuthenticated: false,
  userGroups: []
};


const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    setAuthentication(state, action) {
      state.isAuthenticated = action.payload.isAuthenticated;
    },

    setUserGroups(state, action) {
      state.userGroups = action.payload.userGroups;
    }
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, setAuthentication, setUserGroups } = menu.actions;
