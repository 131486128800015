import { DashboardOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined
};

const docs = {
  id: 'group-docs',
  title: '',
  type: 'group',
  children: [
    {
      id: 'docs',
      title: 'Documentação Backend',
      type: 'item',
      url: '/docs',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

export default docs;
