import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const PushNotificationPage = Loadable(lazy(() => import('pages/push-notification')));

const route = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <PushNotificationPage />
    }
  ]
};

export default function NotificationRoutes() {
  return useRoutes([route]);
}
