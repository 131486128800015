import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const TransactionPage = Loadable(lazy(() => import('pages/transaction')));

const route = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <TransactionPage />
    }
  ]
};

export default function TransactionRoutes() {
  return useRoutes([route]);
}
