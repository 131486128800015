import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const Docs = Loadable(lazy(() => import('pages/docs')));

const route = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Docs />
    }
  ]
};

export default function DocsRoutes() {
  return useRoutes([route]);
}
