import { DashboardOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined
};

const financialReport = {
  id: 'group-financial-report',
  title: '',
  type: 'group',
  children: [
    {
      id: 'financial-report',
      title: 'Relatório Financeiro',
      type: 'item',
      url: '/financial-report',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

export default financialReport;
