import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';


const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const route = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    }
  ]
};

export default function MainRoutes(){
  return useRoutes([route]);
}
