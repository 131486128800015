import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import 'simplebar/src/simplebar.css';
import 'assets/third-party/apex-chart.css';
import App from './App';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import Login from './pages/authentication/Login';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { store } from 'store';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import NotificationRoutes from 'routes/NotificationRoutes';
import TransactionRoutes from './routes/TransactionRoutes';
import ChargersRoutes from 'routes/ChargersRoutes';
import FinancialReportRoutes from 'routes/FinancialReportRoutes';
import DocsRoutes from 'routes/DocsRoutes';

const PrivateRoute = ({ children, requiredGroups }) => {
  const { isAuthenticated } = useSelector((state) => state.menu);
  const { userGroups } = useSelector((state) => state.menu);

  const hasRequiredGroup = requiredGroups.every((group) => userGroups.includes(group));

  return isAuthenticated && hasRequiredGroup ? children : <Navigate to="/login" replace />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute requiredGroups={[]}>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </PrivateRoute>
    )
  },
  {
    path: '/push-notification',
    element: (
      <PrivateRoute requiredGroups={[]}>
        <ReduxProvider store={store}>
          <ThemeCustomization>
            <ScrollTop>
              <NotificationRoutes />
            </ScrollTop>
          </ThemeCustomization>
        </ReduxProvider>
      </PrivateRoute>
    )
  },
  {
    path: '/login',
    element: (
      <ReduxProvider store={store}>
        <ThemeCustomization>
          <ScrollTop>
            <Login />
          </ScrollTop>
        </ThemeCustomization>
      </ReduxProvider>
    )
  },
  {
    path: '/transaction',
    element: (
      <PrivateRoute requiredGroups={[]}>
        <ReduxProvider store={store}>
          <ThemeCustomization>
            <ScrollTop>
              <TransactionRoutes />
            </ScrollTop>
          </ThemeCustomization>
        </ReduxProvider>
      </PrivateRoute>
    )
  },
  {
    path: '/chargers',
    element: (
      <PrivateRoute requiredGroups={[]}>
        <ReduxProvider store={store}>
          <ThemeCustomization>
            <ScrollTop>
              <ChargersRoutes />
            </ScrollTop>
          </ThemeCustomization>
        </ReduxProvider>
      </PrivateRoute>
    )
  },
  {
    path: '/financial-report',
    element: (
      <PrivateRoute requiredGroups={[]}>
        <ReduxProvider store={store}>
          <ThemeCustomization>
            <ScrollTop>
              <FinancialReportRoutes />
            </ScrollTop>
          </ThemeCustomization>
        </ReduxProvider>
      </PrivateRoute>
    )
  },
  {
    path: '/docs',
    element: (
      <PrivateRoute requiredGroups={[]}>
        <ReduxProvider store={store}>
          <ThemeCustomization>
            <ScrollTop>
              <DocsRoutes />
            </ScrollTop>
          </ThemeCustomization>
        </ReduxProvider>
      </PrivateRoute>
    )
  }
]);

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <RouterProvider router={router} />
      </ReduxProvider>
    </React.StrictMode>
  );
}
