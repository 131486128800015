import Cookies from 'js-cookie';
import { appSources } from './adminhttp';

export class AppService {

  

  async login(username, password) {
    let source = appSources('https://api.yellotmob.com.br/authadmin/api/adminlogin/');
    const response = source.post('', { username, password });
    return response;
  }

  async getTransactionMonthAndWeekApi() {
    let source = appSources('https://api.yellotmob.com.br/transaction/api/transactionmonthandweekapi/');
    const response = source.get('');
    return response;
  }

  async getTransactionCards() {
    let source = appSources('https://api.yellotmob.com.br/transaction/api/transactioncardsview/');
    const response = source.get('');
    return response;
  }

  async getLastTransactions() {
    let source = appSources('https://api.yellotmob.com.br/transaction/api/lasttransactions/');
    const response = source.get('');
    return response;
  }

  async getPageTransactions(page, page_size) {
    let source = appSources(`https://api.yellotmob.com.br/transaction/api/transactionpage/?page=${page}&page_size=${page_size}`);
    const response = source.get('');
    return response;
  }

  async getTransaction(transaction) {
    let source = appSources(`https://api.yellotmob.com.br/transaction/api/transactiondashboardview/?transaction=${transaction}`);
    const response = source.get('');
    return response;
  }

  async getMeasurement(transaction, unit, start) {
    let source = appSources(
      `https://api.yellotmob.com.br/transaction/api/measurementtransaction/?transaction=${transaction}&measurement_type=${unit}&first=${start}`
    );
    const response = source.get('');
    return response;
  }

  async getAllClients() {
    let source = appSources('https://api.yellotmob.com.br/authclient/api/user/');
    const response = source.get('');
    return response;
  }

  async getAllRegisteredExpoToken() {
    let source = appSources('https://api.yellotmob.com.br/notification/expo-token/');
    const response = source.get('');
    return response;
  }

  async getAllChargers(){
    let source = appSources('https://api.yellotmob.com.br/chargepoint/api/chargepointadmin/');
    const response=source.get()
    return response
  }

  async pushNotification(user_id, title, body, data) {
    const source = appSources('https://api.yellotmob.com.br/notification/push-notification/');
    const response = source.post('', { user_id, title, body, data });
    return response;
  }

  async pushMultiNotification(user_id, title, body, data) {
    const source = appSources('https://api.yellotmob.com.br/notification/multi-push-notification/');
    const response = source.post('', { user_id, title, body, data });
    return response;
  }

  async TransactionPerMonth() {
    const source = appSources('https://api.yellotmob.com.br/transaction/api/transactionpermonth/');
    const response = source.get('');
    return response;
  }

  async InputNatural(input) {
    const source = appSources('https://api.yellotmob.com.br/transaction/api/inputnatural/');
    const response = source.post('', { input: input });
    return response;
  }

  async getTransactionReport(cnpj,start_date,end_date) {
    const token=await Cookies.get('token');
    const source = appSources(`https://api.yellotmob.com.br/transaction/api/transactionreport/?cnpj=${cnpj}&start=${start_date}&end=${end_date}`,`Bearer ${token}`);
    const response = source.get('');
    return response;
  }

  async getTransactionReportSeries(cnpj,start_date,end_date) {
    const token=await Cookies.get('token');
    const source = appSources(`https://api.yellotmob.com.br/transaction/api/transactionreportseries/?cnpj=${cnpj}&start=${start_date}&end=${end_date}`,`Bearer ${token}`);
    const response = source.get('');
    return response;
  }
}
