import {lazy} from "react";
import {useRoutes} from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";

const ChargersPage = Loadable(lazy(() => import("pages/chargers")));

const route = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <ChargersPage />
    }
  ]
};

export default function ChargersRoutes() {
  return useRoutes([route]);
}
