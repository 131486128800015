import { DashboardOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined
};

const pushNotification = {
  id: 'group-notification',
  title: '',
  type: 'group',
  children: [
    {
      id: 'push-notification',
      title: 'Notificações',
      type: 'item',
      url: '/push-notification',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

export default pushNotification;
