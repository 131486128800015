import axios from 'axios';


function appSources(url='',token="Token dcb83bfa9fa49710a8aed50921c7ca3da3007eef") {
    return axios.create({
        baseURL: url,
        headers: {
            "Content-type": "application/json",
            "Authorization": token
        }
    });
}

export { appSources as appSources };
