import { DashboardOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined
};

const chargers = {
  id: 'group-chargers',
  title: '',
  type: 'group',
  children: [
    {
      id: 'chargers',
      title: 'Estações de Recarga',
      type: 'item',
      url: '/chargers',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

export default chargers;
