import chargers from './chargers';
import dashboard from './dashboard';
import financialReport from './financial-report';
import pushNotification from './push-notification';
import transaction from './transaction';
import docs from "./docs";

const menuItems = {
  items: [dashboard, pushNotification, transaction,chargers,financialReport,docs]
};

export default menuItems;
