import {lazy} from "react";
import {useRoutes} from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";

const FinancialReportPage = Loadable(lazy(() => import("pages/financial-report")));

const route = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <FinancialReportPage />
    }
  ]
};

export default function FinancialReportRoutes() {
  return useRoutes([route]);
}
