// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
import myImage from '../../yellotmoblogo.png';

const Logo = () => {

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <>
      <div style={{fontSize: 20, display: "flex"}}><div><img src={myImage} style={{maxWidth: 50, display: "flex", justifyContent: "center", flexDirection: "column"}} alt="Descrição" /></div> <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>YellotMob</div></div>
    </>
  );
};

export default Logo;
