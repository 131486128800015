import { DashboardOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined
};

const transaction = {
  id: 'transaction',
  title: '',
  type: 'group',
  children: [
    {
      id: 'transaction',
      title: 'Transactions',
      type: 'item',
      url: '/transaction',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

export default transaction;
